import React, { createContext, useState } from 'react';
import _ from 'lodash';

const GlobalContext = createContext();

export function GlobalProvider({ context, children, staticContext }) {
  const [currentContext, setCurrentContext] = useState(context || {});

  const handleUpdateContext = (updatedContext = {}) => {
    const mergedContext = _.merge({}, currentContext, updatedContext, staticContext);

    setCurrentContext(mergedContext);
  };

  return (
    <GlobalContext.Provider
      value={{
        context: currentContext,
        updateContext: handleUpdateContext,
        staticContext
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
