/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_LANGUAGES,
  SET_LANG
} from 'src/actions/languageActions';

const initialState = {
  languages: typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.languages ? window.__REACT_INIT_DATA__.languages : [],
  lang: typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.lang ? window.__REACT_INIT_DATA__.lang : '',
  langPrefix: typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.langPrefix ? window.__REACT_INIT_DATA__.langPrefix : '',
  localization: typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.localization ? window.__REACT_INIT_DATA__.localization : 0,
};
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.languages) delete window.__REACT_INIT_DATA__.languages;
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.lang) delete window.__REACT_INIT_DATA__.lang;
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.langPrefix) delete window.__REACT_INIT_DATA__.langPrefix;

if (typeof window === 'object') {
  const language = initialState.languages.filter((l) => l._id === initialState.lang)[0];

  initialState.country = language?.country || null;
  if (language.logo && language.h1Position) {
    initialState.logo = language.logo;
    initialState.h1Position = language.h1Position;
  }
}

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGES: {
      const { languages } = action.payload;

      return produce(state, (draft) => {
        draft.languages = languages;
      });
    }

    case SET_LANG: {
      const { lang } = action.payload;

      return produce(state, (draft) => {
        draft.lang = lang;
      });
    }

    default: {
      return state;
    }
  }
};

export default languageReducer;
