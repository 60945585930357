import axios from 'axios';
import authService from 'src/services/authService';
import { API_URI } from 'src/constants';
import { session } from 'src/utils/cookies';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);

      session();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (errors) {
      dispatch({ type: LOGIN_FAILURE });
      throw errors;
    }
  };
}

export function setUserData(user, runSession) {
  if (runSession) session();
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    try {
      await authService.logout();

      session();
  
      dispatch({
        type: LOGOUT
      });
    }
    catch (ex) {
      throw ex;
    }
  };
}

export function register(values) {
  return async (dispatch) => {
    try {
      const user = await authService.registerUser(values);

      session();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    }
    catch (ex) {
      throw ex;
    }
  };
}

export function updateProfile(update) {
  return async (dispatch) => {
    try {
      await new Promise((res, rej) => {
        axios
          .post(`${API_URI}/user/update`, update, { withCredentials: true })
          .then((response) => {
            if (response.data.user) {
              dispatch({
                type: UPDATE_PROFILE,
                payload: response.data
              });
              res();
            }
            else {
              rej(response.data.errors);
            }
          })
          .catch((error) => {
            rej({ submit: error.message });
          });
      });
    }
    catch (ex) {
      throw ex;
    }
  };
}
