import React/*, { lazy, Suspense }*/ from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { SettingsProvider } from 'src/context/SettingsContext';
import { SnackbarProvider } from 'notistack';
import { restoreSettings } from 'src/utils/settings';
// import Management from './Management';
// import Front from './Front';
import useLang from 'src/hooks/useLang';
import socketConnect from 'src/hooks/useSocket';
import LoadData from 'src/components/LoadData';
import SplashScreen from 'src/components/SplashScreen';
import LoadingScreen from 'src/components/LoadingScreen';
import { IS_SERVER } from 'src/constants';
import 'src/libraries/jqueryPlugins';

import 'src/assets/css/translates.css';
import './App.css';

const Management = loadable(() => import('./Management'), { fallback: SplashScreen });
const Front = loadable(() => import('./Front'), { fallback: LoadingScreen });

const settings = restoreSettings();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(er) {
    return { hasError: true };
  }

  render() {
    if (typeof window == 'object' && !this.onPopstate) {
      this.onPopstate = () => this.state.hasError && this.setState({ hasError: false });
      window.addEventListener('popstate', this.onPopstate);
    }
    return this.state.hasError ? '' : this.props.children;
  }
}

const originalHistoryActions = {};

function App() {
  const lang = useLang('App');
  socketConnect();

  if (!IS_SERVER) {
    ['pushState', 'replaceState'].forEach((a) => {
      if (!(a in originalHistoryActions)) {
        originalHistoryActions[a] = window.history[a];

        window.history[a] = function (...args) {
          const res = originalHistoryActions[a].apply(this, args);
          const ev = new Event(a.toLowerCase());
          ev.arguments = args;
          window.dispatchEvent(ev);

          return res;
        };
      }
    });
  }

  return (
    <ErrorBoundary>
      <LoadData>
        <SettingsProvider settings={settings}>
          <Switch>
            <Route path={IS_SERVER ? '/management' : lang.l('/management')} render={(props) => <Management {...props}/>} />
            <Route path="*" render={(props) => <SnackbarProvider maxSnack={1}><Front {...props}/></SnackbarProvider>} />
          </Switch>
        </SettingsProvider>
      </LoadData>
    </ErrorBoundary>
  );
}

export default App;
