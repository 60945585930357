import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'src/serviceWorker';
import AppWrapper from 'src/AppWrapper';
import { loadableReady } from '@loadable/component';

loadableReady(() => {
  ReactDOM.hydrate(
    <AppWrapper />,
    document.getElementById('root')
  );
})

serviceWorker.register();
