import React from 'react';
import { Link } from 'react-router-dom';
import { IS_SERVER } from 'src/constants';
import $ from 'jquery';

export default React.forwardRef(({children, to, onClick, ...rest}, ref) => {
  if (IS_SERVER) {
    return <a href={to}{...rest}>{children}</a>;
  } else {
    const handleClick = (ev) => {
      if (onClick) onClick(ev);
      if (!ev.defaultPrevented) {
        $('html, body').animate({ scrollTop: 0 }, 500);
      }
    }

    return <Link onClick={handleClick} to={to} {...rest}{...ref ? { ref } : {}}>{children}</Link>
  }
});