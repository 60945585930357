import io from 'socket.io-client';
import { WEBSOCKET_URI, IS_SERVER } from 'src/constants';
import fingerprint from 'src/utils/fingerprint';
import Helper from 'src/front/helpers/Helper';

let socket;

const createSocket = async () => {
  if (!IS_SERVER) {
    const isPWA = Helper.isPWA();

    socket = io(WEBSOCKET_URI, {
      auth: {
        cookie: typeof document == 'object' ? document.cookie : '',
        fingerprint: await fingerprint(),
        clientId: await Helper.getEverCookie('clientId'),
        displayResolution: `${window.screen.width}x${window.screen.height}`,
        site: window.location.host,
        isPWA,
      }
    });

    const addPageViewed = () => {
      if (socket) socket.emit('page viewed');
    };

    window.addEventListener('popstate', addPageViewed);
    window.addEventListener('pushstate', addPageViewed);
    window.addEventListener('replacestate', addPageViewed);

    socket.on('set clientId', (id) => Helper.setEverCookie('clientId', id));
  }
};

export default () => {
  if (!socket) createSocket();
};

export const socketOnceON = (e, f) => {
  if (socket && !socket.listeners(e).length) socket.on(e, f);
};

export const socketON = (e, f) => { if (socket) socket.on(e, f); };

export const socketOFF = (e, f) => { if (socket) socket.off(e, f); };

export const socketEmit = (e, d) => { if (socket) socket.emit(e, d); };