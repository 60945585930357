import $ from 'jquery';
import React from 'react';
import Link from 'src/front/components/Link';
import {
  API_URI,
  UV_DESCRIPTION,
  WIND_DESCRIPTION,
  DAYS,
  MONTHS,
  IS_SERVER,
} from 'src/constants';
import axios from 'axios';
import fs from 'fs';
import globalConfig from 'src/utils/globalConfig';

export default {
  services: {},
  localizationSeperator: ['%%LOCALIZATION-', '%%'],
  symbols: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
  days: ['יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'יום שבת'],
  hash: function(length = 20) {
    let hash = '';
    while (hash.length < length) {
      const rand = this.rand(0, this.symbols.length - 1);
      hash += this.symbols.substring(rand, rand + 1);
    }
    return hash;
  },
  isNumeric: function (v) {
    return typeof v == 'number' || (typeof v == 'string' && /^[+-]?\d+(?:\.\d+)?$/.test(v) && !isNaN(v));
  },
  inArray: function (n, h) {
    for (var k in h) if (h[k] === n) return true;
    return false;
  },
  ucfirst: function(s) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  },
  compare: function(...elements) {
    const compare = (el1, el2) => {
      if (el1 === el2) return true;
      if (!el1 || !el2 || el1.constructor !== el2.constructor || ![Object, Array].includes(el1.constructor)) return false;

      const el = Object.keys(el1).length > Object.keys(el2).length ? el1 : el2;

      // eslint-disable-next-line no-unused-vars
      for (const k in el) {
        if (!this.compare(el1[k], el2[k])) {
          return false;
        }
      }

      return true;
    };

    for (let e = 1; e < elements.length; e++) {
      if (!compare(elements[e - 1], elements[e])) return false;
    }

    return true;
  },
  arrayShuffle: function(arr) {
    for (let i = arr.length; --i;) {
      const r = this.rand(0, i - 1);
      [arr[i], arr[r]] = [arr[r], arr[i]];
    }

    return arr;
  },
  numberFormat: function (n, d, ds, ts) {
    const [int, dec] = n.toString().split('.');
    const intReversed = int.split(/(?=.)/).reverse();
    const intThousands = [];

    for (let i = 0; i < intReversed.length; i++) {
      if (!(i % 3)) intThousands.push([]);
      intThousands[intThousands.length - 1].push(intReversed[i]);
    }

    let res = intThousands.reverse().map((n) => n.reverse().join('')).join(ts);

    if (d > 0) {
      res += ds;

      for (let i = 0; i < d; i++) {
        res += (dec && dec[i]) || '0';
      }
    }

    return res;
  },
  shortNumber: function (n, l) {
    if (n < 1000) return n;
    if (n < 10000) return IS_SERVER ? `${Math.floor(n / 100) / 10} ${this.services.translate('thousand', {}, l, 0)}` : <>{Math.floor(n / 100) / 10} {l.t('thousand')}</>;
    if (n < 1000000) return IS_SERVER ? `${Math.floor(n / 1000)} ${this.services.translate('thousand', {}, l, 0)}` : <>{Math.floor(n / 1000)} {l.t('thousand')}</>
    if (n < 10000000) return IS_SERVER ? `${Math.floor(n / 100000) / 10} ${this.services.translate('million', {}, l, 0)}` : <>{Math.floor(n / 100000) / 10} {l.t('million')}</>;
    return IS_SERVER ? `${Math.floor(n / 1000000)} ${this.services.translate('million', {}, l, 0)}` : <>{Math.floor(n / 1000000)} {l.t('million')}</>;
  },
  objectToString: function(obj) {
    let res;
    let constructor = obj?.constructor;

    if (!constructor && typeof obj === 'object' && obj !== null && obj.constructor !== Array) constructor = Object;

    switch (constructor) {
      case Array: {
        res = [];

        for (let i = 0; i < obj.length; i++) res.push(this.objectToString(obj[i]));

        res = `[${res.join(',')}]`;
        break;
      }

      case Object: {
        res = [];

        // eslint-disable-next-line no-unused-vars
        for (const k in obj) {
          if (/^[1-9]\d+$|^[\p{L}_][\d\p{L}]*$/u.test(k)) res.push(`${k}:${this.objectToString(obj[k])}`);
          else res.push(`'${k.replace(/(')/g, '\\$1')}':${this.objectToString(obj[k])}`);
        }

        res = `{${res.join(',')}}`;
        break;
      }

      case String: {
        res = `'${obj.replace(/(')/g, '\\$1').replace(/\n\s*/g, '\\n').replace(/\r/g, '\\r')}'`;
        break;
      }

      case Boolean: {
        res = obj.toString();
        break;
      }

      case Date: {
        res = `new Date('${obj.toString()}')`;
        break;
      }

      default: {
        switch (obj?.constructor?.name) {
          case 'ObjectId': {
            res = `'${obj.toString()}'`;
            break;
          }

          default: res = obj;
        }
      }
    }

    return res;
  },
  date: function (f, d, l) {
    var date = d instanceof Date ? d : (d ? new Date(d) : new Date()), _this = this, M = f.indexOf('M') !== -1, F = f.indexOf('F') !== -1,
    _l = f.indexOf('l') !== -1;

    var tokens = {
      d: date.getDate(),
      l: _l ? (IS_SERVER ? this.services.translate(DAYS[date.getDay()], {}, l, 1) : l.t(DAYS[date.getDay()], null, true)) : '',
      j: date.getDate(),
      m: date.getMonth() + 1,
      M: M ? (IS_SERVER ? this.services.translate(MONTHS[date.getMonth()], {}, l, 1) : l.t({ string: MONTHS[date.getMonth()], variant: 1 }, null, true)) : '',
      F: F ? (IS_SERVER ? this.services.translate(MONTHS[date.getMonth()], {}, l, 0) : l.t(MONTHS[date.getMonth()], null, true)) : '',
      Y: date.getFullYear(),
      H: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds()
    };
    return f.replace(new RegExp('(^|[^\\\\])((?:\\\\\\\\)*)(' + Object.keys(tokens).join('|') + ')', 'g'), function () {
      return arguments[1] + arguments[2] + (_this.inArray(arguments[3], ['d', 'm', 'H', 'i', 's']) && tokens[arguments[3]] < 10 ? '0' + tokens[arguments[3]] : tokens[arguments[3]]);
    });
  },
  dateFromFormat: function (d, f) {
    if (!d || !f) return false;
    var dm = d.match(new RegExp('^' + f.replace(/\.|\\/g, function (v) {return '\\' + v;}).replace(/d|m|Y|G|H|i|s|v/g, '(.+?)') + '$')),
    fm = f.match(new RegExp('^' + f.replace(/\.|\\/g, function (v) {return '\\' + v;}).replace(/d|m|Y|G|H|i|s|v/g, function (v) {return '(' + v + ')';}) + '$')),
    date = new Date();
    for (var values = {}, v = 1; v < fm.length; v++) {
      values[fm[v]] = dm[v];
    }
    return new Date(
      'Y' in values ? values.Y : date.getFullYear(),
      'm' in values ? values.m - 1 : date.getMonth(),
      'd' in values ? values.d : date.getDate(),
      'G' in values || 'H' in values ? ('G' in values ? values.G : values.H) : 0,
      'i' in values ? values.i : 0,
      's' in values ? values.s : 0,
      'v' in values ? values.v : 0
    );
  },
  getTimeZoneDate: function(timeZone, date = new Date()) {
    try {
      if (timeZone === 'Antarctica/McMurdo') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + date.getTimezoneOffset() + 13 * 60, date.getSeconds());
      }

      return this.dateFromFormat(date.toLocaleString('en-GB', { timeZone, hour12: false }) + ':' + date.getMilliseconds(), 'd/m/Y, H:i:s:v');
    } catch (ex) {
      return new Date();
    }
  },
  getLocationDate: function(offset) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + offset + date.getTimezoneOffset());
    return date;
  },
  getFieldValue: function (f, l, d) {
    if (!f) return d === undefined ? '' : d;
    if (typeof f == 'string') return f;
    const field = f.filter((v) => v.language === l)[0];
    return field ? field.value : (d === undefined ? '' : d);
  },
  getFrontStyles: function (lang, localization) {
    const rtl = lang.direction === 'rtl' ? '-rtl' : '';
    let styles = [{ src: '/css/common.css' }, { src: `/css/fonts${rtl}.css` }, { src: `/css/keyframes${rtl}.css` }, { src: `/css/style${rtl}.css` }, { src: `/css/media${rtl}.css` } ];

    if (localization.logo && localization.h1Position) {
      styles.push({
        id: 'front-logo-style',
        content: `
          #desktop-header-logo-wrapper h1 {
            top: ${localization.h1Position.y}px !important;
          }
          #desktop-header-logo-wrapper h1 div {
            min-width: ${localization.h1Position.x}px !important;
          }
          #mobile-header-logo-wrapper h2 {
            top: ${localization.h1Position.y / 100 * 75}px !important;
          }
          #mobile-header-logo-wrapper h2 div {
            min-width: ${localization.h1Position.x / 100 * 75}px !important;
          }
          @media (max-width: 1100px) {
            #desktop-header-logo-wrapper h1 {
              top: ${localization.h1Position.y / 100 * 75}px !important;
            }
            #desktop-header-logo-wrapper h1 div {
              min-width: ${localization.h1Position.x / 100 * 75}px !important;
            }
          }
        `,
      });
    }

    return styles;
  },
  transformStyles: function (lang, styles, staticStyles = []) {
    if (IS_SERVER) {
      styles = `staticData.language.direction == 'rtl' ? ${this.objectToString([...styles.map((s) => ({ ...s, src: s.src.substring(0, s.src.length - 4) + '-rtl.css' })), ...staticStyles])} : ${this.objectToString([...styles, ...staticStyles])}`;
    } else if (lang.direction === 'rtl') {
      styles = [...styles.map((s) => ({ ...s, src: s.src.substring(0, s.src.length - 4) + '-rtl.css' })), ...staticStyles];
    } else styles = [...styles, ...staticStyles];

    return styles;
  },
  firstNumZero: function (n) {
    return n < 10 ? `0${n}` : n;
  },
  setSEO: function (p, l, t) {
    $('title').text(l.localizationText(p.title));

    ['keywords', 'description', 'og_title', 'og_description', 'og_image'].forEach((f) => {
      const _ = f.indexOf('_');
      const field = _ === -1 ? f : f.replace('_', ':');
      const attr = _ === -1 ? 'name' : 'property';
      let value = f === 'og_image' ? this.getFieldValue(p[f], l.currentLanguage._id) : l.localizationText(p[f]);

      if (_ !== -1 && !(f in p)) {
        const _f = f.substring(_ + 1);
        if (_f in p) value = _f === 'image' ? this.getFieldValue(p[_f], l.currentLanguage._id) : l.localizationText(p[_f]);
      }

      if (f === 'og_image') {
        if (value) value = this.getFileUrl(t, value);
        else if (l.currentLocalization.og_image) value = this.getFileUrl('language', l.currentLocalization.og_image);
      }

      if (value) {
        if ($(`meta[${attr}="${field}"]`).length) $(`meta[${attr}="${field}"]`).attr('content', value);
        else $('head').append(`<meta ${attr}="${field}" content="${value}"/>`);
      } else if ($(`meta[${attr}="${field}"]`).length) {
         $(`meta[${attr}="${field}"]`).remove();
      }
    });
  },
  translate: function (string, from, to) {
    return new Promise((res, rej) => {
      axios
        .post(`${API_URI}/lang/translate`, { string, from, to }, { withCredentials: true })
        .then(({ data }) => res(data || string))
        .catch((err) => rej(new Error(err.response.data)));
    });
  },
  translateField: async function (f, cl, ll, v, s, ta) {
    const translates = {};
    const gn = ll.filter((l) => l._id === cl)[0].googleName;

    const isNeedTranslate = (l) => {
      if (ta === true) return true;
      if (ta === 'without localizations') return !/%%LOCALIZATION-/.test(v[`${f}_${l._id}`]);
      if (ta?.constructor === Object) return l._id in ta;
      return !v[`${f}_${l._id}`];
    };

    if (v[`${f}_${cl}`]) {
      await Promise.all(ll.map(async (l) => {
        if (cl !== l._id && isNeedTranslate(l)) {
          const translate = this.fixTokens(await this.translate(v[`${f}_${cl}`], gn, l.googleName));

          if (ta?.constructor === Object) {
            const localizationsValues = this.parseLocalizationsText(v[`${f}_${l._id}`], l.localizations.map((l) => l.shortName));

            l.localizations.forEach((loc, i) => {
              if (ta[l._id].includes(i)) {
                localizationsValues[loc.shortName] = translate;
              }
            });

            translates[`${f}_${l._id}`] = this.createTextByLocalizations(localizationsValues);
          } else translates[`${f}_${l._id}`] = translate;
          s(`${f}_${l._id}`, translates[`${f}_${l._id}`]);
        }
      }));
    }
    return translates;
  },
  fixTokens: function (t) {
    const tokens = {};
    let offset = t.indexOf('%%');

    while (offset > -1) {
      const next = t.indexOf('%%', offset + 2);

      if (next === -1) break;

      const token = t.substr(offset, - offset + (offset = next + 2));

      tokens[token] = token.replace(/ /g, '');

      offset = t.indexOf('%%', offset);
    }

    if (Object.keys(tokens).length) {
      t = t.replace(new RegExp(Object.keys(tokens).join('|'), 'g'), (t) => tokens[t]);
    }

    return t;
  },
  copyToBuffer: function (t) {
    var textarea = document.createElement('textarea');
    textarea.value = t;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  },
  setCookie: function (name, value, options = {}) {
    options = { path: '/', ...options };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    // eslint-disable-next-line no-unused-vars
    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      if (options[optionKey] !== true) {
        updatedCookie += '=' + options[optionKey];
      }
    }
  
    document.cookie = updatedCookie;
  },
  getCookie: function (name) {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + encodeURIComponent(name).replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },
  setGlobalCookie: function (languages, name, value) {
    return new Promise(async (resolve) => {
      const domain = `${window.location.protocol}//${window.location.host}`;
      const domains = languages.reduce((a, v) => (v.localizations.forEach((l) => l.domain && a.push(l.domain)), a), [globalConfig('defaultClientUri')]).filter((d) => d !== domain);

      this.setCookie(name, value, { 'max-age': 60 * 60 * 24 * 365 * 100 * 1000 });

      await Promise.all(domains.map((domain) => new Promise((resolve) => {
        axios
          .post(`${domain}/set-cookie`, { [name]: value }, { withCredentials: true })
          .then(resolve)
          .catch(resolve);
      })));

      resolve();
    });
  },
  setEverCookie: function (name, value) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 100);

    this.setCookie(name, value, { expires: date });
    if ('localStorage' in window) window.localStorage.setItem(name, value);
  },
  getEverCookie: async function (name) {
    const cookieValue = this.getCookie(name);
    const localStorageValue = 'localStorage' in window ? window.localStorage.getItem(name) : undefined;
    const value = cookieValue !== undefined ? cookieValue : localStorageValue;
    const date = new Date();
    date.setFullYear(date.getFullYear() + 100);

    if (cookieValue !== value) this.setCookie(name, value, { expires: date });
    if ('localStorage' in window && localStorageValue !== value) window.localStorage.setItem(name, value);

    return value;
  },
  getQueryData: function () {
    if (typeof window == 'undefined') return {};

    return window.location.search.substring(1).split('&').reduce((a, v) => {
      const [key, value] = v.split('=');

      a[decodeURIComponent(key)] = value ? decodeURIComponent(value) : '';

      return a;
    }, {});
  },
  getTextSize: function (content, styles) {
    styles = { ...styles, position: 'absolute', opacity: 0 };
    const el = document.createElement('div');
    // eslint-disable-next-line no-unused-vars
    for (let p in styles) el.style[p] = styles[p];
    el.innerHTML = content;
    document.body.appendChild(el);
    const size = { width: el.offsetWidth, height: el.offsetHeight };
    el.parentNode.removeChild(el);
    return size;
  },
  detectBrowser: function () {
    var patterns = [
      [/MSIE|Trident/i, 'ie'],
      [/Edge/i, 'edge'],
      [/Firefox/i, 'firefox'],
      [/Chrome|CriOS/i, 'chrome'],
      [/Safari/i, 'safari'],
      [/Opera/i, 'opera']
    ];
    for (var p = 0; p < patterns.length; p++) {
      if (patterns[p][0].test(navigator.userAgent)) {
        return patterns[p][1];
      }
    }
    return '';
  },
  detectOperatingSystem: function () {
    var patterns = [
      [/windows nt 10/i, 'Windows 10'],
      [/windows nt 6.3/i, 'Windows 8.1'],
      [/windows nt 6.2/i, 'Windows 8'],
      [/windows nt 6.1/i, 'Windows 7'],
      [/windows nt 6.0/i, 'Windows Vista'],
      [/windows nt 5.2/i, 'Windows Server 2003/XP x64'],
      [/windows nt 5.1/i, 'Windows XP'],
      [/windows xp/i, 'Windows XP'],
      [/windows nt 5.0/i, 'Windows 2000'],
      [/windows me/i, 'Windows ME'],
      [/win98/i, 'Windows 98'],
      [/win95/i, 'Windows 95'],
      [/win16/i, 'Windows 3.11'],
      [/macintosh|mac os x/i, 'Mac OS X'],
      [/mac_powerpc/i, 'Mac OS 9'],
      [/android/i, 'Android'],
      [/iphone/i, 'iPhone'],
      [/linux/i, 'Linux'],
      [/ubuntu/i, 'Ubuntu'],
      [/ipod/i, 'iPod'],
      [/ipad/i, 'iPad'],
      [/blackberry/i, 'BlackBerry'],
      [/webos/i, 'Mobile']
    ];
    for (var p = 0; p < patterns.length; p++) {
      if (patterns[p][0].test(navigator.userAgent)) {
        return patterns[p][1];
      }
    }
    return '';
  },
  detectDeviceType: function (userAgent) {
    if (typeof userAgent !== 'string') return 'bot';
    else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) return 'tablet';
    else if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substring(0, 4))) {
      return 'mobile';
    }
    return 'desktop';
  },
  cloneObject: function(obj) {
    if ([Object, Array].includes(obj?.constructor)) {
      const newObj = obj.constructor === Object ? { ...obj } : [...obj];

      // eslint-disable-next-line no-unused-vars
      for (let k in newObj) newObj[k] = this.cloneObject(newObj[k]);

      return newObj;
    } else return obj;
  },
  pager: function({page, perPage, total, pagerSize = 5, arrows = false}) {
    const pages = Math.ceil(total / perPage);
    if (pages < pagerSize) pagerSize = pages;

    let start = page - Math.floor((pagerSize - 1) / 2);
    if (start < 1) start = 1;
    else if (start + pagerSize - 1 > pages) start = pages - pagerSize + 1;

    const pager = [];
    if (arrows) pager.push({ page: page > 1 ? parseInt(page) - 1 : 0, title: '<' });
    for (let p = start; p < start + pagerSize; p++) pager.push({ page: p, title: p });
    if (arrows) pager.push({ page: page < pages ? parseInt(page) + 1 : 0, title: '>' });

    return pager;
  },
  detectSize: function(c, s) {
    const el = document.createElement('div');

    s = { ...s, position: 'fixed', visibility: 'hidden', opacity: 0 };
    // eslint-disable-next-line no-unused-vars
    for (let k in s) {
      el.style[k] = s[k];
    }
    el.innerHTML = c;
    document.body.appendChild(el);

    const rect = el.getBoundingClientRect();
    const size = { width: rect.width, height: rect.height };

    document.body.removeChild(el);

    return size;
  },
  toReact: function(elements, params) {
    const replaces = { '&lt;': '<', '&gt;': '>' };
    return (
      <>
        {
          elements.map(
            (e) => {
              if (typeof e === 'string') return e.replace(new RegExp(Object.keys(replaces).join('|'), 'g'), (r) => replaces[r]);

              const element = e.name === 'a' && params.reactLink ? Link : e.name;

              return React.createElement(
                element, 
                e.attributes.match(/[^\s]+?=".*?"/g)?.filter((a) => a)?.reduce((a, v) => {
                  let [attr, val] = v.split('=');

                  if (attr === 'style') {
                    val = (val || '').split(';').reduce((a, v) => {
                      if (v) {
                        const [name, value] = v.split(':');
                        if (name && value) a[name.trim()] = value.trim();
                      }
                      return a;
                    }, {});
                  } else val = val ? val.replace(/^["']|["']$/g, '') : '';

                  switch (attr) {
                    case 'href': e.name === 'a' && params.reactLink && (attr = 'to'); break;
                    case 'class': attr = 'className'; break;
                  }

                  a[attr] = val;

                  return a;
                }, {}) || {}, 
                e.children.length ? this.toReact(e.children, params) : undefined
              );
            }
          )
        }
      </>
    );
  },
  parseHtmlString: function(string) {
    const uncloseTagElements = ['br'];
    const elements = [];
    const openedElements = [];
    let element = null;
  
    for (let s = 0, closingTag = null; s < string.length; s++) {
      if (string[s] === '<') {
        if (element) {
          if (openedElements.length) openedElements[openedElements.length - 1].children.push(element);
          else elements.push(element);
          element = null;
        }
  
        if (string[s + 1] === '/') {
          closingTag = '';
          s++;
        } else {
          element = { name: [], children: [], attributes: '' };
  
          if (openedElements.length) openedElements[openedElements.length - 1].children.push(element);
          else elements.push(element);
    
          openedElements.push(element);
        }
      } else if (string[s] === '>') {
        if (closingTag !== null) {
          if (openedElements[openedElements.length - 1].name === closingTag) openedElements.splice(openedElements.length - 1);
          closingTag = null;
        } else {
          if (element.name.constructor === Array) element.name = element.name.join('');
          if (uncloseTagElements.includes(element.name.replace(/\/$/))) openedElements.splice(openedElements.length - 1);
          element = null;
        }
      } else if (element && element.constructor === Object && element.name.constructor === Array && string[s] === ' ') {
        element.name = element.name.join('');
      } else if (element && element.constructor === Object && element.name.constructor === Array) {
        if (string[s] !== '/') element.name.push(string[s]);
      } else if (element && element.constructor === Object && element.name.constructor === String) {
        element.attributes += string[s];
      } else if (closingTag !== null) {
        closingTag += string[s];
      } else {
        if (!element) element = string[s];
        else element += string[s];
      }
    }
  
    if (element) elements.push(element);

    return elements;
  },
  getWeatherIcon: function(i) {
    return i || 'd000';
  },
  getCurrentWeatherIcon: function(w, t) {
    if (w?.current?.icon) return w.current.icon;

    const dateInLocation = this.getTimeZoneDate(t);
    const hourlyDateString = this.date('Y-m-dTH:00', dateInLocation.getTime());
    const dailyDateString = hourlyDateString.substring(0, 10);
    const todayWeather = w?.day && w.day.filter((d) => d.date === dailyDateString)[0];
    let icon = 'd000';

    if (w?.hourly) {
      let found = false;
      for (let i = w.hourly.length; i--;) {
        if (!found && w.hourly[i].date === hourlyDateString) found = true;
        if (found && w.hourly[i].icon) {
          icon = w.hourly[i].icon;
          break;
        }
      }
    }

    if (todayWeather?.sunset && /\d+:\d+/.test(todayWeather.sunset)) {
      const sunsetDate = new Date(`${dailyDateString}T${todayWeather.sunset}`);

      if (dateInLocation.getTime() > sunsetDate.getTime()) {
        if (icon.startsWith('d')) icon = `n${icon.substring(1)}`;
      } else {
        if (icon.startsWith('n')) icon = `d${icon.substring(1)}`;
      }
    }

    return icon;
  },
  getHourlyWeatherIcon: function(w, i, t) {
    if (w?.hourly && w.hourly[i]?.icon) return w.hourly[i].icon;

    let icon = 'd000';

    if (w?.hourly && w.hourly[i]) {
      const dailyDateString = w.hourly[i].date.substring(0, 10);
      const todayWeather = w?.day && w.day.filter((d) => d.date === dailyDateString)[0];

      for (let j = i; j--;) {
        if (w.hourly[j]?.icon) {
          icon = w.hourly[j].icon;
          break;
        }
      }

      if (todayWeather?.sunset && /\d+:\d+/.test(todayWeather.sunset)) {
        const hourlyDate = new Date(w.hourly[i].date);
        const sunsetDate = new Date(`${dailyDateString}T${todayWeather.sunset}`);

        if (hourlyDate.getTime() > sunsetDate.getTime()) {
          if (icon.startsWith('d')) icon = `n${icon.substring(1)}`;
        } else {
          if (icon.startsWith('n')) icon = `d${icon.substring(1)}`;
        }
      }
    }

    return icon;
  },
  getDayWeatherIcon: function(dw, w) {
    if (dw?.icon) return dw.icon;

    const hourlyForDay = dw?.date ? w?.hourly?.filter((h) => h.date.startsWith(dw.date) && h.icon.startsWith('d')) : null;
    const icons = {};
    let icon = 'd000';
    let iconRepeats = 0;

    if (hourlyForDay) {
      for (const hourly of hourlyForDay) {
        if (!(hourly.icon in icons)) icons[hourly.icon] = 1;
        else icons[hourly.icon]++;
      }
    }

    for (const k in icons) {
      if (icons[k] > iconRepeats) {
        icon = k;
        iconRepeats = icons[k];
      }
    }

    return icon;
  },
  rand: function(min, max) {
    return min + Math.floor((max - min + 1) * Math.random());
  },
  rmDir: function(dir) {
    fs.readdirSync(dir).forEach((f) => fs.lstatSync(`${dir}/${f}`).isFile() ? fs.unlinkSync(`${dir}/${f}`) : this.rmDir(`${dir}/${f}`));
    fs.rmdirSync(dir);
  },
  getCloudText: function(c) {
    if (c > 75) return 'cloudy';
    else if (c > 50) return 'partly cloudy';
    else if (c > 25) return 'partly cloudy';
    else return 'clear';
  },
  getWindDirectionName: function(w) {
    const directions = {
      N: 'Northern',
      NE: 'Northeastern',
      E: 'Eastern',
      SE: 'Southeastern',
      S: 'Southern',
      SW: 'Southwestern',
      W: 'Western',
      NW: 'Northwestern',
    };
    return w in directions ? directions[w] : '';
  },
  getUltravioletText: function(u) {
    if (this.isNumeric(u)) {
      // eslint-disable-next-line no-unused-vars
      for (let k in UV_DESCRIPTION) {
        const [min, max] = k.split('-');

        if (parseInt(min) <= parseInt(u) && parseInt(u) <= parseInt(max)) return UV_DESCRIPTION[k];
      }
    }
    return '';
  },
  getWindText: function(w) {
    if (this.isNumeric(w)) {
      // eslint-disable-next-line no-unused-vars
      for (let k in WIND_DESCRIPTION) {
        const [min, max] = k.split('-');

        if (parseInt(min) <= parseInt(w) && parseInt(w) <= parseInt(max)) return WIND_DESCRIPTION[k];
      }
    }
    return '';
  },
  timeString: function(seconds, lang) {
    const string = [];
    const times = [
      ['years', 60 * 60 * 24 * 365],
      ['months', 60 * 60 * 24 * 30],
      ['days', 60 * 60 * 24],
      ['hours', 60 * 60],
      ['minutes', 60],
    ];

    times.forEach((t) => {
      const quantity = Math.floor(seconds / t[1]);

      if (quantity) string.push(lang.t(`!q ${t[0]}`, { '!q': quantity }, true));

      seconds %= t[1];
    });

    if (seconds) string.push(lang.t(`!q seconds`, { '!q': seconds }, true));

    return string.join(' ');
  },
  isPWA() {
    if (window.navigator.standalone) return true;

    //const modes = ['fullscreen', 'standalone', 'minimal-ui'];
    const modes = ['standalone'];
    for (let i = 0; i < modes.length; i++) {
      if (window.matchMedia('(display-mode: ' + modes[i] + ')').matches) return true;
    }

    return false
  },
  saveFileUrl(type) {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'user-avatar': return `${globalConfig('storageUri')}/load-user-avatar`;
    }
  },
  removeFileUrl(type) {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'user-avatar': return `${globalConfig('storageUri')}/remove-user-avatar`;
    }
  },
  getFileUrl(type, name) {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'weather-icon': return `${globalConfig('storageUri')}/weather-icons/${name}`;
      case 'weather-warning': return `${globalConfig('storageUri')}/weather-warnings/${name}`;
      case 'language': return `${globalConfig('storageUri')}/languages/${name}`;
      case 'logotype': return `${globalConfig('storageUri')}/logotypes/${name}`;
      case 'user-avatar': return `${globalConfig('storageUri')}/avatars/${name}`;
      case 'page': return `${globalConfig('storageUri')}/pages/${name}`;
      case 'country': return `${globalConfig('storageUri')}/countries/${name}`;
      case 'region': return `${globalConfig('storageUri')}/regions/${name}`;
      case 'city': return `${globalConfig('storageUri')}/cities/${name}`;
      case 'images': return `${globalConfig('storageUri')}/images/${name}`;
      case 'icons': return `${globalConfig('storageUri')}/icons/${name}`;
      case 'news': return `${globalConfig('storageUri')}/news/${name}`;
      case '404-background': return `${globalConfig('storageUri')}/404-background/${name}`;
      case 'beach': return `${globalConfig('storageUri')}/beaches/${name}`;
      case 'article': return `${globalConfig('storageUri')}/article/${name}`;
    }
  },
  getLocalizationText: function(text, localization) {
    if (typeof text != 'string') return '';
    if (text.indexOf(this.localizationSeperator[0]) !== -1) {
      let match = text.match(new RegExp(`${this.localizationSeperator[0]}(?:[a-zA-Z-]+,)*${localization}(?:,[a-zA-Z-]+)*${this.localizationSeperator[1]}`));

      if (match) {
        const index = match.index + match[0].length;
        const nextIndex = text.indexOf(this.localizationSeperator[0], index);
        return text.substring(index, nextIndex === -1 ? text.length : nextIndex).replace(/^(<\/.*?>)+/, '');
      }

      return '';
    } else return text;
  },
  parseLocalizationsText: function(text, allowedLocalizations) {
    const values = {};
    let index;

    if ((index = text.indexOf(this.localizationSeperator[0])) !== -1) {
      do {
        const index1 = index + this.localizationSeperator[0].length;
        const index2 = text.indexOf(this.localizationSeperator[1], index1);
        const index3 = index2 + this.localizationSeperator[1].length;

        index = text.indexOf(this.localizationSeperator[0], index3);

        const localizations = text.substring(index1, index2);
        const localizationsText = text.substring(index3, index === -1 ? text.length : index).replace(/^(<\/.*?>)+/, '');

        localizations.split(',').forEach((loc) => allowedLocalizations.includes(loc) && (values[loc] = localizationsText));
      } while (index !== -1);

      allowedLocalizations.forEach((loc) => !(loc in values) && (values[loc] = ''));
    } else {
      allowedLocalizations.forEach((loc) => values[loc] = text);
    }

    return values;
  },
  createTextByLocalizations: function (localizationsValues) {
    const checkedLocalizations = [];
    const result = [];

    for (const k in localizationsValues) {
      if (!checkedLocalizations.includes(k)) {
        checkedLocalizations.push(k);
  
        const sameLocalizations = [k];
  
        for (const l in localizationsValues) {
          if (!checkedLocalizations.includes(l) && localizationsValues[k] == localizationsValues[l]) {
            checkedLocalizations.push(l);
            sameLocalizations.push(l);
          }
        }
  
        result.push({ localizations: sameLocalizations.join(','), text: localizationsValues[k] });
      }
    }

    return result.length == 1 ? result[0].text : result.map((r) => `${this.localizationSeperator[0]}${r.localizations}${this.localizationSeperator[1]}${r.text}`).join('');
  },
  getMicrodataParams: function() {
    return this.cloneObject(window.__REACT_INIT_DATA__.microdata);
  },
  generateMicrodataAddress: function({ microdata, language, localization }) {
    let address = null;
    const streetAddress = this.getLocalizationText(this.getFieldValue(microdata.streetAddress, language._id), language.localizations[localization].shortName);
    const postOfficeBoxNumber = this.getLocalizationText(this.getFieldValue(microdata.postOfficeBoxNumber, language._id), language.localizations[localization].shortName);
    const addressLocality = this.getLocalizationText(this.getFieldValue(microdata.addressLocality, language._id), language.localizations[localization].shortName);
    const addressRegion = this.getLocalizationText(this.getFieldValue(microdata.addressRegion, language._id), language.localizations[localization].shortName);
    const postalCode = this.getLocalizationText(this.getFieldValue(microdata.postalCode, language._id), language.localizations[localization].shortName);
    const addressCountry = this.getLocalizationText(this.getFieldValue(microdata.addressCountry, language._id), language.localizations[localization].shortName);
  
    if (streetAddress || postOfficeBoxNumber || addressLocality || addressRegion || postalCode || addressCountry) {
      address = { '@type': 'PostalAddress' };
  
      if (streetAddress) address.streetAddress = streetAddress;
      if (postOfficeBoxNumber) address.postOfficeBoxNumber = postOfficeBoxNumber;
      if (addressLocality) address.addressLocality = addressLocality;
      if (addressRegion) address.addressRegion = addressRegion;
      if (postalCode) address.postalCode = postalCode;
      if (addressCountry) address.addressCountry = addressCountry;
    }
  
    return address;
  },
  strToUint8Array: function(string) {
    const padding = '='.repeat((4 - string.length % 4) % 4);
    const base64 = (string + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
  addAlternateDomains: function (domains) {
    const d = [];
    for (const domain of domains) {
      const alternateDomain = /^https?:\/\/www\./.test(domain) ? domain.replace(/^(https?:\/\/)www\.(.*)/, '$1$2') : domain.replace(/^(https?:\/\/)(.*)/, '$1www.$2');
      d.push(domain, alternateDomain);
    }
    return d;
  },
  token: function (token, value) {
    switch (token) {
      case 'DOMAIN': return value.replace(/^www\./, '');
    }

    return value;
  },
  asyncReplace: async function (search, replaceFunction, subject) {
    const promises = [];
  
    subject.replace(search, (v) => promises.push(replaceFunction(v)));
  
    const data = await Promise.all(promises);
  
    return subject.replace(search, () => data.shift());
  },
};