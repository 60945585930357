import $ from 'jquery';

(function () {
  function slider(el) {
    var _this = this;
    this.el = el;
    this.totalSlides = $(this.el).find('> .slider-slides > li').length;
    this.slidesMoved = 0;
    this.animationDuration = 300;
    this.canAnimate = true;
    this.createButtons();
    this.touchEvents();
    $(window).resize(function () {
      _this.createButtons();
    });
  }
  slider.prototype = {
    createButtons: function () {
      var sliderWidth = $(this.el).width(), width = 0, _this = this;
      this.visibleSlides = 0;
      $(this.el).find('> .slider-slides > li').each(function () {
        var slideWidth = $(this).width();
        slideWidth += parseFloat($(this).css('padding-right'));
        slideWidth += parseFloat($(this).css('padding-left'));
        slideWidth += parseFloat($(this).css('border-right-width'));
        slideWidth += parseFloat($(this).css('border-left-width'));

        if (width + slideWidth <= sliderWidth) {
          _this.visibleSlides++;
          width += slideWidth + parseFloat($(this).css('margin-left'));
        }
        else {
          return false;
        }
      });

      $(this.el).find('> .slider-buttons > li').remove();

      if (this.totalSlides > this.visibleSlides) {
        $(this.el).find('> .slider-buttons').addClass('with-buttons');
        if (this.slidesMoved > this.totalSlides - this.visibleSlides) this.slidesMoved = this.totalSlides - this.visibleSlides;
        for (var s = 0; s < this.totalSlides - this.visibleSlides + 1; s++) {
          $(this.el).find('> .slider-buttons').append('<li' + (s === this.slidesMoved ? ' class="current"' : '') + '><a href="#"></a></li>');
        }
        $(this.el).find('> .slider-buttons a').click(function (ev) {
          ev.preventDefault();
          if (_this.canAnimate && !$(this).parent().hasClass('current')) _this.switchSlide(this);
        });
      }
      else {
        $(this.el).find('> .slider-buttons').removeClass('with-buttons');
        this.slidesMoved = 0;
      }
      $(this.el).find('> .slider-slides').css('margin-right', - this.getMove() + 'px');
    },
    switchSlide: function (b) {
      this.canAnimate = false;
      var _this = this;
      $(this.el).find('> .slider-buttons li').removeClass('current');
      $(b).parent().addClass('current');
      $(this.el).find('> .slider-buttons a').each(function (n) {
        if (this === b) {
          _this.slidesMoved = n;
          return false;
        }
      });
      $(this.el).find('> .slider-slides').animate({marginRight: - this.getMove() + 'px'}, this.animationDuration, function () {
        _this.canAnimate = true;
      });
    },
    getMove: function () {
      var _this = this, move = 0, maxMove = $(this.el).find('> .slider-slides').width() - $(this.el).width();
      $(this.el).find('> .slider-slides > li').each(function (n) {
        if (_this.slidesMoved > n) {
          move += $(this).width();
          move += parseFloat($(this).css('padding-right'));
          move += parseFloat($(this).css('padding-left'));
          move += parseFloat($(this).css('border-right-width'));
          move += parseFloat($(this).css('border-left-width'));
          move += parseFloat($(this).css('margin-left'));
        }
      });
      return maxMove > 0 && move > maxMove ? maxMove : move;
    },
    touchEvents: function () {
      var startX, _this = this,
      moveFunction = function (ev) {
        ev.originalEvent.preventDefault();
        var moveX;
        if (ev.originalEvent.type === 'mousemove') moveX = ev.originalEvent.clientX;
        else moveX = ev.originalEvent.touches[0].clientX;
        if (Math.abs(startX - moveX) > 10) {
          try {
            if (startX > moveX) {
              if (_this.slidesMoved && _this.canAnimate) {
                _this.canAnimate = false;
                _this.slidesMoved--;
                // eslint-disable-next-line no-throw-literal
                throw '';
              }
            }
            else {
              if (_this.slidesMoved < _this.totalSlides - _this.visibleSlides && _this.canAnimate) {
                _this.canAnimate = false;
                _this.slidesMoved++;
                // eslint-disable-next-line no-throw-literal
                throw '';
              }
            }
          }
          catch (ex) {
            $(_this.el).find('> .slider-buttons li').removeClass('current');
            $(_this.el).find('> .slider-buttons li:nth-child(' + (_this.slidesMoved + 1) + ')').addClass('current');
            $(_this.el).find('> .slider-slides').animate({marginRight: - _this.getMove() + 'px'}, _this.animationDuration, function () {
              _this.canAnimate = true;
            });
          }
          startX = moveX;
        }
      };
      $(this.el).on('touchstart mousedown', function (ev) {
        if (ev.originalEvent.type === 'mousedown') startX = ev.originalEvent.clientX;
        else startX = ev.originalEvent.touches[0].clientX;

        $(_this.el).on('touchmove mousemove', moveFunction);
      });
      $(this.el).on('touchend mouseup', function () {
        $(_this.el).off('touchmove mousemove', moveFunction);
      });
    }
  };
  if ($.fn) {
    $.fn.slider = function () {
      this.each(function () {
        new slider(this);
      });
    };
  }
})();