import React, { useContext, useRef } from 'react';
import $ from 'jquery';

const drawBackground = (element) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const clearWidth = element.width() +
      parseFloat(element.css('padding-left')) + parseFloat(element.css('padding-right')) +
      parseFloat(element.css('border-left-width')) + parseFloat(element.css('border-right-width'));
  const clearHeight = element.height() +
      parseFloat(element.css('padding-top')) + parseFloat(element.css('padding-bottom')) +
      parseFloat(element.css('border-top-width')) + parseFloat(element.css('border-bottom-width'));
  const clearX = element.offset().left - (window.pageXOffset ? window.pageXOffset : (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : (document.body.scrollLeft ? document.body.scrollLeft : 0)));
  const clearY = element.offset().top - (window.pageYOffset ? window.pageYOffset : (document.documentElement.scrollTop ? document.documentElement.scrollTop : (document.body.scrollTop ? document.body.scrollTop : 0)));
  const clearRadius = parseFloat(element.css('border-top-left-radius')) * 2;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  ctx.fillStyle = $('#popup-background').css('background-color');
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = '#fff';
  ctx.lineJoin = 'round';
  ctx.lineWidth = clearRadius;
  ctx.globalCompositeOperation = 'destination-out';

  ctx.strokeRect(clearX + (clearRadius / 2), clearY + (clearRadius / 2), clearWidth - clearRadius, clearHeight - clearRadius);
  ctx.fillRect(clearX + (clearRadius / 2), clearY + (clearRadius / 2), clearWidth - clearRadius, clearHeight - clearRadius);

  return canvas.toDataURL();
};

export default ({ fronContext } = {}) => {
  const close = useRef(null);
  const context = useContext(FrontContext) || fronContext;
  const contextRef = useRef(null);
  const timerRef = useRef(null);
  const styleRef = useRef(null);
  const timerCallbackRef = useRef(null);

  contextRef.current = context;

  return ({ event, element, sizeElement, name, onShowStart, onShowEnd, onCloseStart, onCloseEnd, closeCheck, closeRef, openUpdateContext = {} }) => {
    if (event) event.preventDefault();
    if (!element.hasClass('opened')) {
      if (close.current) document.removeEventListener('click', close.current);

      close.current = (ev, ...params) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          timerCallbackRef.current();
        }

        if (!ev || $(ev.target).hasClass('popup-wrapper') || $(ev.target).closest('a, button').hasClass('close-popup') || ev.target.id === 'popup-background' || (closeCheck && closeCheck(ev))) {
          if (ev && !$(ev.target).closest('a, button').hasClass('no-prevent-default')) ev.preventDefault();

          if (onCloseStart) onCloseStart(...params);
  
          $('#popup-background').css('background', `url(${drawBackground(sizeElement || element)}) 0 0 no-repeat`);
  
          contextRef.current.setProperty('popup', false);
          document.removeEventListener('click', close.current);
          close.current = null;
          if (closeRef) closeRef.current = null;
  
          setTimeout(() => {
            //$('body').removeAttr('style');
            $('body').removeClass(`popup-opened popup-name-${name}`);
            $('#popup-background').removeAttr('style');

            if (styleRef.current) {
              styleRef.current.parentNode.removeChild(styleRef.current);
              styleRef.current = null;
            }

            //$('header').removeAttr('style');
            //if ($('body').hasClass('landing')) {
            //  if (landingShiftElements.reduce((a, v) => a || element.is(v), false)) element.removeAttr('style');
            //}

            if (onCloseEnd) onCloseEnd(...params);
          }, 300);
        }
      };

      document.addEventListener('click', close.current);

      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

      if (!styleRef.current) {
        styleRef.current = document.createElement('style');
        styleRef.current.textContent = `:root { --scrollbar-width: ${scrollbarWidth}px }`;
        document.head.appendChild(styleRef.current);
      }

      if (closeRef) closeRef.current = close.current;

      element.addClass('opened');

      if (onShowStart) onShowStart();

      //$('header').css({
      //  paddingRight: scrollbarWidth + 'px',
      //  boxSizing: 'border-box',
      //});
      //
      //if ($('body').hasClass('landing')) {
      //  if (landingShiftElements.reduce((a, v) => a || element.is(v), false)) {
      //    element.css('transform', `translate(-${window.innerWidth - document.documentElement.clientWidth}px, 0)`);
      //  }
      //}
      //if (!$('body').attr('style')) $('body').css({
      //  overflow: 'hidden',
      //  paddingRight: window.innerWidth - document.documentElement.clientWidth + 'px',
      //  boxSizing: 'border-box',
      //});

      $('body').addClass(`popup-opened popup-name-${name}`);
  
      $('#popup-background').attr('style', `background: url(${drawBackground(sizeElement || element)}) 0 0 no-repeat`);
  
      contextRef.current.setProperty({ ...openUpdateContext, popup: name });

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerCallbackRef.current();
      }

      timerCallbackRef.current = () => {
        $('#popup-background').removeAttr('style');

        if (onShowEnd) onShowEnd();

        timerRef.current = null;
      };

      timerRef.current = setTimeout(timerCallbackRef.current, 300);
    }
  };
};

export const FrontContext = React.createContext();