import React, {
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { API_URI, IS_SERVER } from 'src/constants';
import { setLanguages, setLang } from 'src/actions/languageActions';

const LoadData = ({ children }) => {
  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.language);

  useEffect(() => {
    if (!languages.length) {
      try {
        axios
          .get(`${API_URI}/load-data`)
          .then(async ({ data: { lang, languages } }) => {
            await dispatch(setLang(lang));
            await dispatch(setLanguages(languages));
          });
      }
      catch (ex) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!IS_SERVER && !languages.length) return <SplashScreen />;

  return children;
};

export default LoadData;