import { useSelector, useDispatch } from 'react-redux';
import useLang from 'src/hooks/useLang';
import axios from 'axios';
import { API_URI } from 'src/constants';
import { setUserData } from 'src/actions/accountActions';
import { useSnackbar } from 'notistack';
import { session } from 'src/utils/cookies';

export function useUserCan() {
  const user = useSelector((state) => state.account.user);

  return (permissions) => {
    if (!user) return false;

    if (user.superAdmin) return true;

    if (!user.role || !user.role.permissions || typeof user.role.permissions != 'object' || typeof user.role.permissions.includes != 'function') return false;

    if (typeof permissions == 'string') {
      return user.role.permissions.includes(permissions);
    } else {
      let allowed = 0;
      permissions[0].forEach((p) => {
        if (user.role.permissions.includes(p)) allowed++;
      });
      return permissions[1] === 'or' ? (allowed ? true : false) : allowed === permissions[0].length;
    }

    // eslint-disable-next-line no-unreachable
    return false;
  };
}

export function useSimulateUser() {
  const lang = useLang('hookUserSimulation');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return {
    simulate: (id) => {
      axios
          .post(`${API_URI}/user/simulate`, { id }, { withCredentials: true })
          .then(async ({ data }) => {
            if (!data?.user?.role?.permissions?.includes('adminPanelAccess')) {
              await session();
              document.location.href = lang.l('/account-management');
            } else {
              await dispatch(setUserData(data.user, true));
              enqueueSnackbar(
                lang.t(
                  `You are simulate user '!name', with role '!role'`,
                  { 
                    '!name': `${data.user.firstName} ${data.user.lastName}`,
                    '!role': data.user.role ? lang.localizationText(data.user.role.name) : lang.t('Without role', null, true)
                  }
                ),
                {
                  variant: 'success'
                }
              );
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data || lang.t('Something wrong'), {
              variant: 'error'
            });
          });
    },
    exitSimulation: () => {
      axios
          .post(`${API_URI}/user/exit-simulation`, {}, { withCredentials: true })
          .then(async ({ data }) => {
            await dispatch(setUserData(data.user, true));
            enqueueSnackbar(
              lang.t('You are exit from simulation'),
              {
                variant: 'success'
              }
            );
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data || lang.t('Something wrong'), {
              variant: 'error'
            });
          });
    }
  };
}