import fs from 'fs';
import getAppDir from 'src/utils/getAppDir';
import path from 'path';

let config;
const cloneObject = function(obj) {
  if ([Object, Array].includes(obj.constructor)) {
    const newObj = obj.constructor === Object ? { ...obj } : [...obj];

    // eslint-disable-next-line no-unused-vars
    for (const k in newObj) newObj[k] = cloneObject(newObj[k]);

    return newObj;
  } else return obj;
};

export default (c) => {
  if (!config) {
    if (typeof window == 'object') {
      config = window.__REACT_INIT_DATA__.config;
      //delete window.__REACT_INIT_DATA__.config;
    } else {
      config = JSON.parse(fs.readFileSync(path.resolve(getAppDir(__dirname, '../..'), '../global_config.json')));
    }
  }

  let res = cloneObject(config);

  if (c) {
    c.split('.').forEach((p) => {
      res = res[p];
    });
  }

  return res;
};