import axios from 'axios';
import { API_URI } from 'src/constants';
import Helper from 'src/front/helpers/Helper';
import { socketEmit } from 'src/hooks/useSocket';

export const session = () => new Promise((resolve) => {
  axios
      .get(`${API_URI}/user/session`, { withCredentials: true })
      .then(({ data: { cookies, domains } }) => {
        try {
          let needUpdate = false;

          // eslint-disable-next-line no-unused-vars
          for (let k in cookies) {
            const value = Helper.getCookie(k);

            if (value !== cookies[k]) {
              needUpdate = true;
              Helper.setCookie(k, cookies[k], { 'max-age': 60 * 60 * 24 * 365 * 100 * 1000 });
            }
          }

          if (needUpdate) {
            socketEmit('auth', document.cookie);

            const domain = `${window.location.protocol}//${window.location.host}`;
            domains.forEach((d) => {
              try {
                if (d !== domain) {
                  axios
                    .post(`${d}/set-cookie`, cookies, { withCredentials: true })
                    //.then((res) => console.log(d, res))
                    .catch(() => {});
                }
              } catch (ex) {}
            });
          }

          resolve(needUpdate);
        } catch (ex) {}
        resolve(false);
      })
      .catch(() => resolve(false));
});
