import React from 'react';
import { Router, StaticRouter } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { enableES5 } from 'immer';
import { Provider } from 'react-redux';
import { configureStore } from 'src/store';
import { GlobalProvider } from 'src/context/GlobalContext';
import { IS_SERVER } from 'src/constants';
import App from './App';

enableES5();

const store = configureStore();
const history = typeof document != 'undefined' ? createBrowserHistory() : createMemoryHistory();
const isScanningConstants = !IS_SERVER && window.__REACT_INIT_DATA__?.isScanningConstants;
// const isDevelopmentMode = !IS_SERVER && window.__REACT_INIT_DATA__?.developmentMode;

function AppWrapper({ defaultStore, location, staticContext, context = { isScanningConstants } }) {
  const SelectedRouter = IS_SERVER ? StaticRouter : Router;

  if (!IS_SERVER/*isDevelopmentMode*/) {
    const importedServerStyles = document.querySelectorAll('.imported-server-styles, .material-ui-ssr-style');

    for (const styles of importedServerStyles) styles.remove();
  }

  return (
    <GlobalProvider context={context} staticContext={staticContext}>
      <Provider store={defaultStore || store}>
        <SelectedRouter {...(typeof document != 'undefined' ? { history } : { location, context })}>
          <App/>
        </SelectedRouter>
      </Provider>
    </GlobalProvider>
  );
}

export default AppWrapper;
