export const SET_LANGUAGES = '@language/set-languages';
export const SET_LANG = '@language/set-lang';

export function setLanguages(languages) {
  return (dispatch) => dispatch({
    type: SET_LANGUAGES,
    payload: {
      languages
    }
  });
}

export function setLang(lang) {
  return (dispatch) => dispatch({
    type: SET_LANG,
    payload: {
      lang
    }
  });
}