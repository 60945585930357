import { createHash } from 'crypto';
import { IS_SERVER } from 'src/constants';
import Helper from 'src/front/helpers/Helper';

export default async () => {
  if (!IS_SERVER) {
    const canvas = document.createElement('canvas');
    const fp = await Helper.getEverCookie('_fp');

    canvas.width = '540';
    canvas.height = '60';

    //canvas.style.position = 'fixed';
    //canvas.style.left = '0';
    //canvas.style.top = '0';
    //document.body.appendChild(canvas);

    const ctx = canvas.getContext('2d');

    ctx.fillStyle = '#ff6100';
    ctx.shadowColor = '#ff6100';
    ctx.shadowBlur = 5;
    ctx.fillRect(10, 10, 140, 40);
    ctx.font = ctx.font.replace(/\d+px/, 'bold 30px');
    ctx.fillStyle = '#006f96';
    ctx.shadowOffsetX = 3;
    ctx.shadowOffsetY = 3;
    ctx.shadowColor = '#63db34';
    ctx.textBaseline = 'top';

    ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', '0123456789'].forEach((t, n) => ctx.fillText(t, 0, n * 30));

    const canvasUrl1 = canvas.toDataURL();
    const canvasUrl2 = canvas.toDataURL();
    let string = canvasUrl1 === canvasUrl2 ? canvasUrl1 : 'canvas fake';

    string += Helper.detectOperatingSystem();
    string += Helper.detectBrowser();
    string += window.screen.width * window.screen.height;
    string += window.navigator.language.substring(0, 2);
    if ('localStorage' in window) string += 'localStorage';
    //if ('languages' in window.navigator) string += window.navigator.languages.join(',');
    if ('hardwareConcurrency' in window.navigator) string += window.navigator.hardwareConcurrency.toString();

    const hash = createHash('sha256').update(string).digest('hex');

    if (canvasUrl1 === canvasUrl2) {
      if (fp !== hash) Helper.setEverCookie('_fp', hash);

      return hash;
    } else return fp || hash;
  }
}