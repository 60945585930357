import axios from 'axios';
import { API_URI } from 'src/constants';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  registerUser = (values) => new Promise((res, rej) => {
    axios
      .post(`${API_URI}/user/register`, values, { withCredentials: true, headers: { 'x-referer': window.location.href } })
      .then((response) => {
        if (response.data.user) res(response.data.user);
        else rej(new Error(JSON.stringify(response.data)));
      })
      .catch((err) => {
        rej({ common: err.message });
      });
  });

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios.post(`${API_URI}/user/login`, { email, password }, { withCredentials: true })
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.errors);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  init = () => new Promise((resolve, reject) => {
    axios.get(`${API_URI}/user/init`, { withCredentials: true })
      .then((response) => {
        if (!response.data.error) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  logout = async () => new Promise((resolve, reject) => {
    axios.get(`${API_URI}/user/logout`, { withCredentials: true })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const authService = new AuthService();

export default authService;
